.toggle-switch
  #customSwitch11.custom-control-input
  ~ .custom-control-label:before {
  background: #ebe9f4;
}
.toggle-switch
  #customSwitch11.custom-control-input
  ~ .custom-control-label:after {
  background: #363062;
}
.nav-text,
.deznav .metismenu > li > a,
.deznav .metismenu ul a {
  white-space: pre;
}

/* //#ebe9f4
//#363062 */
